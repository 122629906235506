import {
  TDeviceConfiguration,
  TReactions,
  TReactionType,
} from "src/data/devices/types/deviceTypes"
import { TTemperatureUnit } from "src/data/user/user"
import { convertTemperatureValue } from "src/utils/l10n"

export const ReactionTypes: { [key: string]: TReactionType } = {
  TemperatureHigh: "home:temperature:high",
  TemperatureLow: "home:temperature:low",
  HumidityHigh: "home:humidity:high",
  HumidityLow: "home:humidity:low",
  Tamper: "device:mount:removed",
  SoundLevelHigh: "sound_level_high",
  SoundLevelHighQuietHours: "sound_level_high_quiet_hours",
}

/** Check if the given date object is within the start and end time
 *
 * @param {String} curr The current time, on the format HH:mm (24h clock)
 * @param {String} start The start time, on the format HH:mm (24h clock)
 * @param {String} end The end time, on the format HH:mm (24h clock)
 */
export const isWithinStartAndEndHours = (
  curr: string,
  start: string,
  end: string
) => {
  // If end time is smaller than start time, it means that the time spans
  // over midnight
  if (end < start) {
    return curr >= start || curr <= end
  } else {
    return curr >= start && curr <= end
  }
}

const isReactionActive = (reaction: TReactions | undefined) => {
  const notifications = reaction?.notifications ?? []
  return notifications.length > 0
}

const getReaction = (
  configuration: TDeviceConfiguration | undefined,
  type: TReactionType
) => {
  const reactions = configuration?.reactions ?? []
  return reactions.find((reaction) => reaction.type === type)
}

export const extractQuietHoursThreshold = (
  configuration: TDeviceConfiguration | undefined
) => {
  const quietHours = configuration?.quiet_hours || {}
  const reaction = getReaction(
    configuration,
    ReactionTypes.SoundLevelHighQuietHours
  )

  return {
    ...quietHours,
    value: reaction?.value,
  }
}

export const extractDaytimeNoiseThreshold = (
  configuration: TDeviceConfiguration | undefined
) => {
  const reaction = getReaction(configuration, ReactionTypes.SoundLevelHigh)
  return {
    enabled: isReactionActive(reaction),
    value: reaction?.value,
  }
}

export function extractActiveNoiseThreshold(
  configuration: TDeviceConfiguration | undefined,
  isNoiseMonitoringEnabled: boolean
): ITimeScopedThreshold[] | undefined {
  const dayTimeThreshold = extractDaytimeNoiseThreshold(configuration)
  const quietHoursThreshold = extractQuietHoursThreshold(configuration)
  const useNoiseThreshold = dayTimeThreshold.enabled || isNoiseMonitoringEnabled
  if (!useNoiseThreshold) {
    // Returning an empty list for a data series makes highcharts render
    // data at 1970
    return undefined
  }

  const thresholds: ITimeScopedThreshold[] = []
  if (dayTimeThreshold.value) {
    thresholds.push({
      value: dayTimeThreshold.value,
    })
  }

  if (quietHoursThreshold.enabled && quietHoursThreshold.value) {
    const start = quietHoursThreshold.start
    const end = quietHoursThreshold.end

    thresholds.push({
      value: quietHoursThreshold.value,
      startHHMM: start,
      endHHMM: end,
    })
  }
  return thresholds
}

export const extractTemperatureThreshold = (
  configuration: TDeviceConfiguration | undefined,
  temperatureUnit: TTemperatureUnit = "C"
): number[] => {
  const reactionHigh = getReaction(configuration, ReactionTypes.TemperatureHigh)
  const reactionLow = getReaction(configuration, ReactionTypes.TemperatureLow)

  const max = reactionHigh?.value
  const min = reactionLow?.value

  const enabled =
    isReactionActive(reactionHigh) && isReactionActive(reactionLow)
  if (!enabled) {
    return []
  }
  const thresholds: number[] = []
  if (min) {
    thresholds.push(Number(convertTemperatureValue(min, temperatureUnit, 0)))
  }
  if (max) {
    thresholds.push(Number(convertTemperatureValue(max, temperatureUnit, 0)))
  }
  return thresholds
}

export const extractHumidityThreshold = (
  configuration: TDeviceConfiguration | undefined
): number[] => {
  const reactionHigh = getReaction(configuration, ReactionTypes.HumidityHigh)
  const reactionLow = getReaction(configuration, ReactionTypes.HumidityLow)
  const enabled =
    isReactionActive(reactionHigh) && isReactionActive(reactionLow)
  if (!enabled) {
    return []
  }
  const thresholds: number[] = []

  const max = reactionHigh?.value
  const min = reactionLow?.value

  if (min) {
    thresholds.push(min)
  }
  if (max) {
    thresholds.push(max)
  }

  return thresholds
}

export interface ILineGraphData {
  datetime: Date
  value: number | undefined
  max?: number
  min?: number
}

export interface ITimeScopedThreshold {
  value: number
  startHHMM?: string // NB: hour is in home local time *not* UTC!
  endHHMM?: string
}

function dateWithinThreshold(
  xDate: Date,
  threshold: ITimeScopedThreshold | undefined
) {
  if (!threshold?.endHHMM || !threshold.startHHMM) {
    return false
  }

  const splitCurrTime = xDate.toTimeString().split(":")
  const currTime = `${splitCurrTime[0]}:${splitCurrTime[1]}`
  const start = threshold.startHHMM
  const end = threshold.endHHMM
  if (isWithinStartAndEndHours(currTime, start, end)) {
    return true
  }
  return false
}

function changeTimeZone(date: Date, timeZone: string) {
  return new Date(date.toLocaleString("en-US", { timeZone }))
}

export function getActiveNoiseThreshold({
  xVal,
  dayTimeThreshold,
  quietTimeThreshold,
  timezone,
}: {
  xVal: ILineGraphData["datetime"]
  dayTimeThreshold: ITimeScopedThreshold | undefined
  quietTimeThreshold: ITimeScopedThreshold | undefined
  timezone: string | undefined
}): number {
  if (!dayTimeThreshold) return NaN
  let xDate: Date
  if (timezone) {
    xDate = changeTimeZone(xVal, timezone)
  } else {
    xDate = xVal
  }

  if (!dateWithinThreshold(xDate, quietTimeThreshold)) {
    return dayTimeThreshold?.value || NaN
  }

  return quietTimeThreshold?.value || NaN
}
